<template>
  <div section-head>
    <section-title>{{ title }}</section-title>
    <section-title-extra v-show="extra !== ''">{{ extra }}</section-title-extra>
    <div class="gap" />
    <slot></slot>
  </div>
</template>
<script>
import SectionTitle from '@/views/components/coaching/SectionTitle.vue';
import SectionTitleExtra from '@/views/components/coaching/SectionTitleExtra.vue';

export default {
  components: { SectionTitleExtra, SectionTitle },
  props: {
    title: {
      type: String,
      default: '',
    },
    extra: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[section-head] {
  .w(100%);.flex;.flex-jc(center);.flex-ai(center);

  .gap {flex-grow: 1;}

  [color-button] {
    & + [color-button] { .ml(8); }
    &.btn-left, &.btn-right {.wh(40, 40)}
    &.btn-left {.t-r(90deg)}
    &.btn-right {.t-r(270deg)}
  }
}
</style>
