<template>
  <component section-title :is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[section-title] {
  @media (max-width: @screen-ml-max) {
    .fs(24)
  }
  @media (min-width: @screen-tp-min) {
    .fs(32)
  }
  .bold;.c(@c-title-black);.chakra();

  + [section-title-extra] {
    .ml(6);
  }
}
</style>
