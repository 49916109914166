<template>
  <label CheckboxSquare>
    <span class="checkbox-label">
      <slot></slot>
    </span>
    <input type="checkbox" v-model="boundValue" :disabled="disabled" />
    <span class="checkbox-checkmark" :class="{'disabled': disabled}"></span>
  </label>
</template>
<script>
export default {
  props: {
    value: {
      type: [Boolean, Array],
      default: false,
    },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    boundValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[CheckboxSquare] { .block; .pointer; .c(#191919);
  input[type='checkbox'] { user-select: none;.hide;}
  input[type='checkbox']:not(:checked) ~ .checkbox-checkmark {
    background-image: none; border: solid 1px #d5d5de;
    &.disabled { .bgc(#f2f2f4); pointer: default; pointer-events: none;}
  }
  .checkbox-checkmark {.bg('@{img}/icon/icon-checked-square.svg');.wh(20, 20);display: inline-block;float: right;.ml(6)}
  .checkbox-label {line-height: 21px;display: inline-block;word-break: break-word;.w(calc(100% - 26px))}
}
</style>
