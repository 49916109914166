<template>
  <component :is="tag" tag @click="$emit('click')" :class="[`theme-${theme}`]">
    <slot>-</slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: [String, Object],
      default: 'li',
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tag] {
  text-align: center;list-style: none;display: inline-block;.fs(12);.p(3, 12, 3, 12);.br(4);.noto;
  & + & {.ml(8)}
  &.theme-default {
    .bgc(#f2f5ff);.c(#787781);
  }

  @profile-themes: {
    @hot: {
      @name: hot;
      @bg: #f7f9ff;
      @c: #658df2;
      @bc: #c6d3ff;
    }
    @base: {
      @name: base;
      @bg: transparent;
      @c: #787781;
      @bc: #ebebeb;
    }
  }

  each(@profile-themes, {
    @name: @value[@name];
    &.theme-profile-@{name} {border-style: solid;border-width: 1px;.fs(12);.lh(15);.bgc(@value[@bg]);.c(@value[@c]);border-color: @value[@bc];}
  });
}
</style>
