<template>
  <component section-title-extra :is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[section-title-extra] {
  @media (max-width: @screen-ml-max) {
    .fs(13)
  }
  @media (min-width: @screen-tp-min) {
    .fs(16)
  }
  .bold;.c(@c-text-gray);.noto;
}
</style>
