<template>
  <div coach-finder>
    <div v-if="isFilterOn" class="dim"></div>
    <div class="content-wrap">
      <article class="left-holder" :class="{on:isFilterOn}">
        <section-head v-if="isNotPC" title="필터" class="top-wrap">
          <button v-if="isNotPC" class="btn-filter" @click="toggleFilter">
            <img src="/img/extra/ico_close_dark.svg" style="vertical-align: middle;" />
          </button>
        </section-head>
        <div class="search-input-holder">
          <ValidationObserver ref="validator">
            <ValidationProvider v-slot="{errors}" name="search" rules="lengthMin:2">
              <CoachSearchInput v-model="searchInput" :hasError="!!errors.length" placeHolder="코치 닉네임, 코치 태그" @search="searchBtnHandler" />
              <ValidationErrors :errors="errors" />
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="search-tag">
          <h3>태그</h3>
          <tag :class="{'hot': tag.priority === 1, 'selected': search === tag.name}" v-for="(tag, index) in tagFilterList" :key="index" @click="setSearchTag(tag.name)">{{ tag.name }}</tag>
        </div>
        <div class="hr" />
        <div class="search-order">
          <h3>정렬순서</h3>
          <button :class="{'selected': order === 'latestOnlineDatetime DESC'}" @click="setSearchOrder('latestOnlineDatetime DESC')">최근 활동한</button>
          <button :class="{'selected': order === 'lessonCount DESC'}" @click="setSearchOrder('lessonCount DESC')">수업 횟수 많은</button>
          <button :class="{'selected': order === 'lessonPrice ASC'}" @click="setSearchOrder('lessonPrice ASC')">가격 낮은</button>
          <button :class="{'selected': order === 'reviewCount DESC'}" @click="setSearchOrder('reviewCount DESC')">후기 많은</button>
          <button :class="{'selected': order === 'reviewRating DESC'}" @click="setSearchOrder('reviewRating DESC')">평점 높은</button>
        </div>
        <div class="hr" />
        <client-only>
          <div v-if="!isCoach" class="recommendation-banner" @click="$router.push({ name: 'CoachMatching' })">
            <img v-if="!isTablet" src="/img/coaching/banner-recommendation.svg" alt="banner-recommendation" />
            <img v-if="isTablet" src="/img/coaching/banner-recommendation-wide.svg" alt="banner-recommendation-wide" />
          </div>
        </client-only>
        <client-only>
          <div v-if="!isCoach" class="question-banner" @click="$router.push({ name: 'CoachQuestions', params: { gameId: 'lol' } })">
            <img v-if="!isTablet" src="/img/coaching/banner-question.svg" alt="banner-question" />
            <img v-if="isTablet" src="/img/coaching/banner-question-wide.svg" alt="banner-question-wide" />
          </div>
        </client-only>
      </article>
      <section class="right-holder">
        <section-head title="코치 찾기" class="top-wrap">
          <button v-if="isNotPC" class="btn-filter" @click="toggleFilter">
            <img src="/img/icon/ico_filter_dark.svg" style="vertical-align: middle;" />
            <span style="vertical-align: middle;"> 필터</span>
          </button>
        </section-head>
        <div class="coach-search-result">
          <div class="search-summary">
            <span class="coach-count">
              <span v-if="search && search.length > 0"><em class="em-bl">{{ search }}</em>(으)로 검색 된</span>
              <em class="em-bk">{{ addCommaDecimal(coachSearchResult.totalCount) }}</em>명의 코치님
            </span>
            <CheckboxSquare v-model="canLesson" class="live-filter">대기중인 코치님만 보기</CheckboxSquare>
          </div>
          <div class="coach-list">
            <CoachRowList :coach-search-result="coachSearchResult" />
            <div v-if="coachSearchResult.items < 1" class="empty-coach-list">
              <img src="/img/coaching/emoji-eyes.svg" alt="no-content-icon" />
              <p style="margin-top: 16px;">검색된 코치님이 없습니다.</p>
              <p>어떤 코치님을 찾고 계신지 알려주세요!</p>
              <button class="selected" @click="openHelpScout()">이런 코치님을 찾고 있어요!</button>
            </div>
            <area-more class="area-more" v-if="hasMore" @click="getMore" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from '@shared/utils/numberUtils';
import { getter } from '@shared/utils/storeUtils';
import CoachRowList from '@/views/components/coaching/coachList/CoachRowList.vue';
import CoachSearchInput from '@/views/components/coaching/CoachSearchInput.vue';
import Tag from '@/views/components/coaching/Tag.vue';
import SectionHead from '@/views/components/coaching/SectionHead.vue';
import CheckboxSquare from '@/views/components/common/input/CheckboxSquare.vue';
import AreaMore from '@/views/components/coaching/question/AreaMore.vue';
import { removeEventHandler, setEventHandler } from '@/utils/eventBusUtils';

export default {
  name: 'CoachFinder',
  components: {
    CoachSearchInput,
    SectionHead,
    CheckboxSquare,
    Tag,
    AreaMore,
    CoachRowList,
  },
  data() {
    return {
      searchInput: '',
      isFilterOn: false,
      tagFilterList: [],
      coachSearchResult: {},
      gameInfo: null,
      topBannerInfo: null,
      pagingSize: 15,
    };
  },
  metaInfo() {
    return {
      title: this.$t('meta.coachFinder.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.coachFinder.title') },
        { vmid: 'description', content: this.$t('meta.coachFinder.description') },
        { vmid: 'url', content: this.$t('meta.coachFinder.url') },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: this.$t('meta.type') },
        { vmid: 'image', content: this.$t('meta.image') },
        { vmid: 'keywords', content: this.$t('meta.coachFinder.keywords') },
      ],
    };
  },
  computed: {
    isNotPC() {
      return this.matchedMediaDevice !== 'D';
    },
    isTablet() {
      return this.matchedMediaDevice === 'T';
    },
    hasMore() {
      return this.coachSearchResult.hasNext;
    },
    cursor: {
      get() {
        return this.$route?.query?.cursor ?? 0;
      },
      set(v) {
        this.$router.push({ query: this.getMergedQuery({ cursor: v }) });
      },
    },
    search: {
      get() {
        return this.$route?.query?.search;
      },
      set(v) {
        this.searchInput = v;
        this.$router.push({ query: this.getMergedQuery({ search: v, cursor: 0 }) });
      },
    },
    canLesson: {
      get() {
        return this.$route?.query?.canLesson === 'true';
      },
      set(v) {
        this.$router.push({ query: this.getMergedQuery({ canLesson: v, cursor: 0 }) });
      },
    },
    order: {
      get() {
        return this.$route?.query?.order ?? 'latestOnlineDatetime DESC';
      },
      set(v) {
        this.$router.push({ query: this.getMergedQuery({ order: v, cursor: 0 }) });
      },
    },
    isCoach: getter('auth', 'isCoach'),
  },
  methods: {
    searchBtnHandler() {
      this.search = this.searchInput;
      if (this.searchInput.length > 0) {
        this.isFilterOn = false;
      }
    },
    toggleFilter() {
      this.isFilterOn = !this.isFilterOn;
    },
    setSearchTag(value) {
      this.search = value;
      this.isFilterOn = false;
      /** GA 전자상거래 추적코드 */
      this.$gtag.event('click_coach_tag', {
        'event_category': 'coach_finder',
        'event_label': 'coach_finder_tag',
        'value': value,
      });
    },
    setSearchOrder(value) {
      this.order = value;
      this.isFilterOn = false;
      /** GA 전자상거래 추적코드 */
      this.$gtag.event('click_coach_list_order', {
        'event_category': 'coach_finder',
        'event_label': 'coach_finder_order',
        'value': value,
      });
    },
    openHelpScout() {
      window.Beacon('open');
    },
    getCoachList() {
      if (this.$route.hash) {
        this.coachSearchResult.nextCursor = 0;
        this.coachSearchResult.items = [];
        this.getMore();
      }
    },
    async getMore() {
      const { nextCursor } = this.coachSearchResult;
      this.coachSearchResult.nextCursor = nextCursor + 1;
      const moreCoachResult = await this.$services.coaching.getCoachList({ cursor: nextCursor, size: this.pagingSize, order: this.order, search: this.search, silent: true });
      this.coachSearchResult.nextCursor = moreCoachResult.nextCursor;
      this.coachSearchResult.hasNext = moreCoachResult.hasNext;
      this.coachSearchResult.totalCount = moreCoachResult.totalCount;
      moreCoachResult.items.forEach(coach => this.coachSearchResult.items.push(coach));

      if (nextCursor > 0) {
        /** GA 전자상거래 추적코드 */
        this.$gtag.event('click_coach_list_more', {
          'event_category': 'coach_finder',
          'event_label': 'coach_finder_more',
          'value': `${this.search} cursor: ${nextCursor}`,
        });
      }
    },
    addCommaDecimal(num) {
      return num ? commaDecimal(num) : 0;
    },
    async resetData() {
      const cursor = this.$route.query.cursor ?? 0;
      const search = this.$route.query.search ?? '';
      const canLesson = this.$route.query.canLesson === 'true';
      const order = this.$route.query.order ?? 'latestOnlineDatetime DESC';
      this.coachSearchResult = await this.$services.coaching.getCoachList({ cursor, size: 15, order, search, canLesson, silent: true });
      this.tagFilterList = await this.$services.coaching.getCoachTags();
    },
  },
  watch: {
    search() {
      this.searchInput = this.search;
    },
    $route: 'resetData',
  },
  beforeDestroy() {
    removeEventHandler('coach-list-get-list');
  },
  async mounted() {
    await this.resetData();
    this.searchInput = this.search;
    setEventHandler('coach-list-get-list', this.getCoachList.bind(this));
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

@media (@ds-up) {
  #app { overflow: unset;
    > .contents-wrapper { overflow: unset;}
  }
}

[coach-finder] {.bgc(#fff);
  > .dim { .abs; .f; .bgc(@c-b07); .lt; z-index: 9999; }
  .content-wrap { .flex; .flex-wrap; .m(0, auto); .pt(60); .pl(20); .pr(20); .pb(50); .max-w(1260);
    .left-holder { .w(352); .t(140); position: sticky; .h(652);
      [validation-errors] {.c(#f00); .fs(12); .mt(8);}
      .search-tag {
        .mt(20);
        .hot { .c(#658df2); .bgc(#f7f9ff); border: 1px solid #c6d3ff;
          &.selected, &:hover { border: 1px solid #658df2;}
        }
        > li {
          .bgc(#fff); border: 1px solid #ebebeb;
          .pointer; .ml(0); .mr(8); .mt(8); .fs(14);
          &.selected, &:hover { border: 1px solid #191919;}
        }
      }
      .search-order { .mt(30);
        > button { .lh(19); .h(36); .p(8, 16, 8, 16); .mr(8); .mt(8); .bgc(#f6f6f6); .c(#787781); border: 1px solid #ebebeb; .fs(14); .br(4);
          &.selected {
            .bgc(#191919);
            .c(#fff)
          }
        }
      }
      .recommendation-banner { .pointer; .mt(30);}
      .question-banner { .pointer; .mt(8);}
    }
    .right-holder { .w(838); .ml(70);
      .btn-filter { .w(84); .h(40); border: 1px solid #ebebeb; .br(50); .c(#191919);
        &:hover {border: 1px solid #191919;}
        &:focus {border: 1px solid #2c81ff;}
      }
      .search-summary { .flex; .pt(30); .pb(30); border-bottom: solid 1px #ebebeb;
        .coach-count { .w(50%); .tl; .c(#777);
          .em-bk { .c(#191919); }
          .em-bl { .c(#2c81ff) }
        }
        .live-filter { .ml(auto); }
      }
      .empty-coach-list {.h(309); .pt(80); .tc;
        > button { .w(224); .p(17, 20); .mt(16); .bgc(#191919); .c(#fff); border: 1px solid #ebebeb; .fs(14); .br(4);
        }
      }
      .area-more { .mt(28); }
    }
  }
  /* PC보다 작을 때 */
  @media (@tl-down) {
    .content-wrap { .pl(0); .pr(0);
      .left-holder { .fix; .w(100%); .h(90%); .p(60, 60, 60, 60); .t(unset); .br-t(20); .bgc(white); .z(10); bottom: calc((100% - 70px) * -1); transition: bottom .3s;
        overflow-y: auto; -ms-overflow-style: none;
        &::-webkit-scrollbar { display: none; }
        &.on { .b(0); z-index: 10000001; /* 채널톡 z-index보다 1 추가 */
          .editor-header .header-holder > button svg { .t-r(0deg); }
        }
        .search-input-holder {.mt(50);}
        .recommendation-banner { .fl; .w(50%);
          > svg {.w(100%);}
        }
        .question-banner { .fl; .mt(30) !important; .w(50%);
          > svg {.w(100%);}
        }
        .hr { .w(100%); .h(1); .bgc(#eaeaea); .mt(40); }
      }
      .right-holder {.w(100%); .pl(50); .pr(50); .ml(0);
      }
    }
  }

  /* 태블릿보다 작을 때 */
  @media (@tp-down) {
    .content-wrap { .pt(20);
      .left-holder { .p(20, 20, 20, 20);
        .search-banner > svg { .w(100%); }
        .recommendation-banner { float: none; .w(100%) !important;;
          > img {.w(100%);}
        }
        .question-banner { float: none; .w(100%) !important;; .mt(8) !important;
          > img {.w(100%);}
        }
      }
      .right-holder { .p(20, 20, 20, 20);
        .search-summary { .flex-wrap(); .pt(20); .pb(20); border-bottom: solid 1px #ebebeb;
          .coach-count { .w(100%); .p(12, 12, 12, 12); .bgc(#f6f6f6); .tl; .c(#777);
            .em-bk { .c(#191919); }
            .em-bl { .c(#2c81ff) }
          }
          .live-filter { .ml(auto); .pt(20); }
        }
      }
    }
  }
  /* 모바일보다 클 때 */
  @media (@tp-up) {
  }

  /* 태블릿보다 클 때 */
  @media (@ds-up) {
    .bgc(#fff);
    .content-wrap { .max-w(1260); .pr(0); .pl(0); }
  }
}
</style>
