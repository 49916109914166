<template>
  <transition-group coach-row-list name="slide-up" tag="div">
    <router-link class="coach-row" v-for="(coach, index) in coachSearchResult.items" :key="coach.coachId" :to="getCoachPageRoute(coach.coachId)">
      <div class="preview-profile-img-wrap">
        <Avatar :info="coach.user" :defaultImgType="'fruit'" class="profile-avatar" dimension="100x100" userProfile />
        <div :class="{online: coach.status === 'ONLINE', coaching: coach.status === 'COACHING' }" class="profile-online"></div>
      </div>
      <div class="coach-info">
        <div class="coach-title">
          <div class="coach-name-area">
            <h1 class="coach-name">{{ coach.user.nickname }}</h1>
            <p class="coach-last-online" v-show="coach.status !== 'ONLINE' && coach.status !== 'COACHING' && coach.latestOnlineDatetime !== null">
              {{ getLastOnlineText(coach.latestOnlineDatetime) }}
            </p>
          </div>
          <div class="btn-favorite" @click.prevent="toggleBookmarkCoach(coach.coachId, coach.isLiked, index)">
            <svg-favorite-active v-show="coach.isLiked" />
            <svg-favorite-inactive v-show="!coach.isLiked" />
          </div>
        </div>
        <ul class="preview-history">
          <li v-for="(history, i) in coach.mainHistory" :key="i">
            {{ history }}
          </li>
        </ul>
        <div class="review">
          <div class="positions">
            <dd>{{ getPositionsText(coach.positions) }}</dd>
            <dt>특화 포지션</dt>
          </div>
          <div class="vr" />
          <div class="comment">
            <dd>{{ addCommaDecimal(coach.reviewCount) }}개</dd>
            <dt>수업 후기</dt>
          </div>
          <div class="vr" />
          <div class="count">
            <dd>{{ addCommaDecimal(coach.lessonCount) }}건</dd>
            <dt>수업 횟수</dt>
          </div>
          <div class="vr" />
          <div class="lowest-price">
            <dd>{{ getPriceText(coach.recommendLessonPrice) }}</dd>
            <dt>최저 가격</dt>
          </div>
          <div v-if="!isMobile" class="detail">자세히 보기</div>
        </div>
        <div v-if="isMobile" class="detail-mobile">
          <div class="btn-detail-mobile"><em>자세히 보기</em></div>
        </div>
        <div class="tag">
          <tag :class="{'hot': tag.priority === 1}" v-for="(tag, index) in coach.tags" :key="index">{{ tag.name }}</tag>
        </div>
      </div>
    </router-link>
  </transition-group>
</template>
<script>
import Avatar from '@shared/components/common/Avatar.vue';
import { commaDecimal } from '@shared/utils/numberUtils';
import { getter } from '@shared/utils/storeUtils';
import { makeHashValues } from '@shared/utils/routerUtils';
import Tag from '@/views/components/coaching/Tag.vue';
import SvgFavoriteActive from '@/views/graphics/svg-favorite-active.vue';
import SvgFavoriteInactive from '@/views/graphics/svg-favorite-inactive.vue';
import { callEvent } from '@/utils/eventBusUtils';
import coach from '@/mixins/coach';
import { LOL_POSITION_TEXT } from '@/constants/lol';

export default {
  name: 'CoachRowList',
  mixins: [coach],
  components: {
    Avatar,
    SvgFavoriteActive,
    SvgFavoriteInactive,
    Tag,
  },
  props: {
    coachSearchResult: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
  },
  methods: {
    addCommaDecimal(num) {
      return num ? commaDecimal(num) : 0;
    },
    /**
     *
     * @param {LolLane[]} positions
     */
    getPositionsText(positions = []) {
      if (positions?.length === 0) return '-';
      return positions.map(p => this.$t(`coaching.${LOL_POSITION_TEXT?.[p]}`)).join('/');
    },
    getPriceText(amount = 0) {
      return this.$t(`coaching.MC_current_point`, { amount: commaDecimal(amount) });
    },
    getLastOnlineText(latestOnlineDatetime) {
      const timeText = this.$fromNowAlt(latestOnlineDatetime);
      return this.$t('coaching.lastOnline', [timeText]);
    },
    async openCoachModal(coachId) {
      this.$router.push(this.$route.fullPath + makeHashValues({ 'modal-tutor-id': coachId }));
    },
    async toggleBookmarkCoach(coachId, isLiked, index) {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      await this.$services.coaching[isLiked ? 'unlikeCoach' : 'likeCoach'](coachId).then(
        () => {
          this.coachSearchResult.items[index].isLiked = !this.coachSearchResult.items[index].isLiked;
        },
      );
      await callEvent('coach-list-get-list');
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[coach-row-list] {
  .coach-row { .flex-wrap; border-bottom: solid 1px #ebebeb; .p(32, 10); .w(832); .pointer; .m(auto);
    &:hover {
      .review {
        .detail { .bgc(#4c8df8) !important; .c(#fff)}
      }
    }
    .preview-profile-img-wrap { .rel; flex: 1; .w(120); .h(120); .br(32);
      .profile-avatar { .w(120); .h(120); .br(32);}
      .profile-online { .abs; .t(102); .w(20); .h(20); .br(32); border: 2px solid #fff; .bgc(#5f5f5f);
        &.online { .bgc(#0ce757); }
        &.coaching { .bgc(#6aa6ff); }
      }
      .skeleton { .w(120); .h(120); .br(32); }
    }
    .coach-info { .w(664);
      .coach-title { .rel;
        .coach-name-area {.flex;.flex-row;.flex-jc(flex-start);.flex-ai(center);
          .coach-name {.ellipsis;flex-shrink: 1;.max-w(500)}
          .coach-last-online {.c(#777);.fs(14);.lh(20);font-weight: 400;.ml(8);white-space: nowrap;}
        }
        .btn-favorite { .abs; .t(0); .r(0);
          > svg { .fill(#a4a3ae); }
          &:hover {
            > svg { .fill(#191919); .stroke(none); }
          }
          &.active {
            > svg { .fill(#2c81ff); .stroke(none); }
          }
        }
      }
      .preview-history { .fs(14); .lh(26); .mt(8); overflow-y: hidden;
        li { .rel; .c(#191919); .ml(12); text-overflow: ellipsis;
          &:after { .cnt(''); .abs; .br(100%); .w(4); .h(4); .t(11); .l(-10); .bgc(#787781); }
        }
      }
      .review {
        .flex; .flex-row; justify-content: space-evenly; .items-center; .tc; .mt(14); .h(90); .bgc(#f6f6f6);
        .vr { .h(40); .w(1); .bgc(#eaeaea); }
        .detail { .w(90); .h(40); .bgc(#e5e5e5); .br(4); .pt(10);}
        dd { .fs(20); .bold }
        dt { .fs(14); .regular; .c(#777);
          div {
            display: inline;
            > img { .w(12.44) }
          }
        }
      }
      .tag {
        .mt(20);
        > li { .bgc(#fff); border: 1px solid #ebebeb; }
        .hot { .c(#658df2); .bgc(#f7f9ff); border: 1px solid #c6d3ff; }
      }
    }
  }
  .slide-up { transition: all 0.25s; }
  .slide-up-enter-active { transition: all 0.5s ease; }
  .slide-up-leave-active { transition: all 0.28s ease; }
  .slide-up-enter { opacity: 0; transform: translateY(100%); }
  .slide-up-leave-active { opacity: 0; }
  /* 태블릿보다 작을 때 */
  @media (@ds-down) {
    .coach-row { .w(100%);
      .preview-profile-img-wrap { flex: 0; .w(120); .mr(32); }
      .coach-info { flex: 1; }
    }
  }
  /* 모바일보다 작을 때 */
  @media (@tp-down) {
    .coach-row { .pt(17);
      .preview-profile-img-wrap { .flex-center; .w(100); .tc; .mr(0); .m(auto);
        .profile-avatar { .w(100); .h(100);}
        .profile-online { .t(92); .l(0); }
      }
      .coach-info { flex: auto !important; .w(100%) !important;
        .coach-title { .tc; .pt(5); .pb(5); .fs(20);
          .coach-name-area {.pl(25);.pr(25);.flex-column;.flex-jc(center);
            .coach-name {.max-w(95%);}
            .coach-last-online {.pl(0)}
          }
          .btn-favorite { .t(-115) !important; }
        }
        .review { .fs(12) !important;
          dd { .fs(14) !important; }
          dt { .fs(12) !important; }
        }
        .detail-mobile { .bgc(#f6f6f6); .pl(16); .pb(16); .pr(16);
          .btn-detail-mobile { .bgc(#e5e5e5); .tr(); .p(14, 16, 14, 16); .fs(12); .br(4);}
        }
        .tag { .mt(10) !important;
          > li {
            .pointer; .ml(0); .mr(8); .mt(8); .fs(12);
          }
        }
      }
    }
  }
}
</style>
